<template lang="pug">
.mx-11
  v-row.mb-10.pt-4
    v-col(md="3")
      v-card 
        v-row(justify="center")
          v-col(md="4").text-center.pl-7
            v-chip(x-large color="primary")
              v-icon(x-large) mdi-book-account
          v-col(md="7") 
            div.subtitle-1 Total Books
            div.font-weight-bold.text-h4 {{totalBooks}}
    v-col(md="3")
      v-card 
        v-row(justify="center")
          v-col(md="4").text-center.pl-7
            v-chip(x-large color="primary")
              v-icon(x-large) mdi-account-multiple
          v-col(md="7") 
            div.subtitle-1 Total Users
            div.font-weight-bold.text-h4 {{totalUsers}}
    v-col(md="2")
      v-card 
        v-row(justify="center")
          v-col(md="6").text-center.pl-7
            v-progress-circular(
              :size="70"
              :width="12"
              :value="percentage(students)"
              color="primary"
            ) {{percentage(students) + '%'}}
          v-col(md="6") 
            div.subtitle-1 Students
            div.font-weight-medium.text-h4 {{students}}
    v-col(md="2")
      v-card 
        v-row(justify="center")
          v-col(md="6").text-center.pl-7
            v-progress-circular(
              :size="70"
              :width="12"
              :value="percentage(faculty)"
              color="primary"
            ) {{percentage(faculty) + '%'}}

          v-col(md="6") 
            div.subtitle-1 Faculty
            div.font-weight-medium.text-h4 {{faculty}}
    v-col(md="2")
      v-card 
        v-row(justify="center")
          v-col(md="6").text-center.pl-7
            v-progress-circular(
              :size="70"
              :width="12"
              :value="percentage(staff)"
              color="primary"
            ) {{percentage(staff) + '%'}}
          v-col(md="6") 
            div.subtitle-1 Staff
            div.font-weight-medium.text-h4 {{staff}}

    v-col(md="6").mt-3
      v-card.mt-4.mx-auto
        v-sheet(
          class="v-sheet--offset mx-auto"
          color="secondary"
          elevation="12"
          max-width="calc(100% - 32px)"
          min-height="60px"
        )
            v-sparkline(
              :labels="months"
              :value="getValues(customViews[0])"
              color="white"
              line-width="2"
              padding="16"
              v-if="dataReady"
              )
            v-sparkline(
              :labels="months"
              color="white"
              :value="[0,0,0,0,0,0,0,0,0,0,0,0]"
              v-else
              line-width="2"
              padding="16"
              )
        v-card-text.pt-0.mx-3
          v-row.text-h5.font-weight-light.font-weight-bold.px-3.mt-1
            v-col(md="8").pa-0.mt-1 Book Views
              span.grey--text.ml-2 {{months[new Date().getMonth()]}} {{selectedYear1}}
            v-col(md="4").pr-7.mt-n4
              v-select(filled rounded dense height="20" :items="years" v-model="selectedYear1" @change="viewYear()")
          div.subtitle-1.mt-n6
            div.font-weight-light Total Views this year:
                span.ml-2.font-weight-bold(v-if="customViews") {{sum(customViews[0])}}
            div.font-weight-light Total views this month:
                span(v-for="value, name in customViews[0]")
                  span(v-if="month==name").ml-2.font-weight-bold {{value}}
            div.font-weight-light Views per day this month:
                span(v-for="value, name in customViews[0]")
                  span(v-if="month==name").ml-2.font-weight-bold {{(value/new Date().getDate()).toFixed(2)}}
            div.font-weight-light Increase of views from last month:
                span.ml-2.font-weight-bold(v-if="customViews") {{ increase(customViews[0]) }}
        v-divider.my-1
        v-card-actions.mx-3
          v-btn(
            color="primary"
            text
            @click="moreViews = !moreViews"
          ) {{ moreViews ? 'Hide Details' : 'More Details' }}
          v-spacer
          v-btn(
          icon
          @click="moreViews = !moreViews"
          )
            v-icon {{ moreViews ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
        v-scroll-y-transition
          div(v-show="moreViews")
            h5.muted.font-weight-light(v-if="error==true") No data Retrived
            h5.muted.font-weight-light(v-else-if="dataReady==false") Loading data
            h5.muted.font-weight-light(v-else-if="customViews.length==0") No data Retrieved
            v-simple-table(v-else dense).mx-15.pb-7
              template(v-slot:default)
                thead
                  tr
                    th.text-center Month
                    th.text-center Views
                tbody.text-center
                  tr(v-for="month in monthNumbers", :key="month.num")
                    td.text-center {{ month.name }}
                    td.text-center.font-weight-medium {{ customViews[0][month.num] }}

      v-card.text-center.mt-5
        v-card-text.pb-0
          h5.font-weight-bold.mb-n1 <v-icon medium class="mb-1" color="primary"> mdi-book-education-outline</v-icon> <span>Top 10 Most Viewed Books </span>
        v-divider
        v-simple-table.pa-5.mt-n4
          template(v-slot:default)
            thead
              tr
                th.text-center Rank
                th.text-center Title
                th.text-center Views
            tbody.text-center
              tr(v-for="book, index in topBooks", :key="book.id")
                td.text-center {{ index+1 }}
                td.text-start {{ book.title }}
                td.text-center.font-weight-bold {{ book.views }}

      v-card(min-height="600").mt-5.text-center
        v-card-text.pb-0
          h5.font-weight-bold.mb-n1 <v-icon medium color="primary" class="mb-1"> mdi-account-badge </v-icon> Admin Logins
        v-divider
        div.text-center.mt-10(v-if="admin == ''")
          v-progress-circular.my-11.mx-auto(:size="70" :width="7" indeterminate color="primary")
        v-simple-table(v-else fixed-header height="540px").px-5.pb-5
          template(v-slot:default)
            thead
              tr
                th.text-center Email
                th.text-center Time In
            tbody.text-center
              tr(v-for="admin in admin", :key="admin.id")
                td.text-center.font-weight-bold {{ admin.email }}
                td.text-center {{ admin.date.toDate().toLocaleString().split('GMT')[0] }}  

    v-col(md="6").mt-3
      v-card.mt-4.mx-auto
        v-sheet(
          class="v-sheet--offset mx-auto"
          color="success"
          elevation="12"
          max-width="calc(100% - 32px)"
          min-height="60px"
        )
          v-sparkline(
            :labels="months"
            :value="getValues(customLogins[0])"
            color="white"
            line-width="2"
            padding="16"
            v-if="dataReadyLogin"
          )
          v-sparkline(
            :labels="months"
            color="white"
            :value="[0,0,0,0,0,0,0,0,0,0,0,0]"
            v-else
            line-width="2"
            padding="16"
          )

        v-card-text.pt-0.mx-3
          v-row.text-h5.font-weight-light.font-weight-bold.px-3.mt-1
            v-col(md="8").pa-0.mt-1 User Logins
              span.grey--text.ml-2 {{months[new Date().getMonth()]}} {{selectedYear}}
            v-col(md="4").pr-7.mt-n4
              v-select(filled rounded dense height="20" :items="years" v-model="selectedYear" @change="loginYear()")
          div.subtitle-1.mt-n6
            div.font-weight-light Total logins this year:
              span.ml-2.font-weight-bold(v-if="customLogins") {{sum(customLogins[0])}}
            div.font-weight-light Total logins this month:
              span(v-for="value, name in customLogins[0]")
                span(v-if="month==name").ml-2.font-weight-bold {{value}}
            div.font-weight-light Logins per day:
              span(v-for="value, name in customLogins[0]")
                span(v-if="month==name").ml-2.font-weight-bold {{(value/new Date().getDate()).toFixed(2)}}
            div.font-weight-light Increase of logins from last month:
              span.ml-2.font-weight-bold(v-if="customLogins") {{ increase(customLogins[0]) }}
        v-divider.my-1
        v-card-actions.mx-3
          v-btn(
            color="primary"
            text
            @click="moreLogins = !moreLogins"
          ) {{ moreLogins ? 'Hide Details' : 'More Details' }}
          v-spacer
          v-btn(
          icon
          @click="moreLogins = !moreLogins"
          )
            v-icon {{ moreLogins ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
        v-scroll-y-transition
          div(v-show="moreLogins")
            h5.muted.font-weight-light(v-if="error==true") No data Retrived
            h5.muted.font-weight-light.ma-7(v-else-if="dataReadyLogin==false") Loading data
            h5.muted.font-weight-light(v-else-if="customLogins.length==0") No data Retrieved
            v-simple-table(v-else dense).mx-15.pb-7
              template(v-slot:default)
                thead
                  tr
                    th.text-center Month
                    th.text-center Logins
                tbody.text-center
                  tr(v-for="month in monthNumbers", :key="month.num")
                    td.text-center {{ month.name }}
                    td.text-center {{ customLogins[0][month.num] }}
      v-card(height="625" :disabled="loadingInsti").text-center.mt-5
        v-card-text.pb-0
          h5.font-weight-bold.mb-n1 <v-icon medium class="mb-1" color="primary"> mdi-domain </v-icon> <span>Users per College</span>
        v-divider
        v-row.mt-n2.px-4.text-center
          v-col(md="4")
            div.caption Show by
            v-select(
              v-model="instiType",
              :items=["Colleges", "CS Institutes"]
              filled,
              rounded,
              dense,
            )
          v-col(md="4")
            div.caption Sort by
            v-select(
              v-model="sortTypeInitial",
              :items="sortSelect"
              item-text="text"
              item-value="value"
              filled,
              rounded,
              dense,
              @change="changeSort()"
            )
          v-col(md="4")
            div.caption Show type
            v-select(
              v-model="showType",
              :items="typeSelect"
              item-text="text"
              item-value="value"
              filled,
              rounded,
              dense,
              @change="changeType"
            )
        v-divider.mt-n2
        div(v-if="loadingInsti")
          v-progress-circular.my-11.mx-auto(:size="70" :width="7" indeterminate color="primary")
        div(v-else-if="!openedInsti").mt-7.mx-7
          v-alert(
            border="left"
            icon="mdi-alert-circle"
            type="primary"
            text
            dense
            ) Please be informed that this process will consume large data read. Viewing this data will add up to the monthly cloud database expense significantly. Use this only when it is needed. Thank you!
          v-btn(
            color="success",
            @click="getInstiData"
          ).mx-auto.mt-3 show
        v-simple-table(v-slot:default fixed-header height="410px" v-else).pa-5.mt-n4
          template
            thead
              tr
                th
                th.text-center 
                  span(v-if="instiType=='Colleges'") College
                  span(v-else) CS Institution
                th.text-center Number
            tbody.text-center(v-if="instiType=='Colleges'")
              tr(v-for="item, i in  orderBy(colDistribution, sortType[0], sortType[1])", :key="i")
                td.text-center {{ i+1 }}
                td.text-start {{ item.institution }}
                td.text-center.font-weight-bold
                  span(v-if="showType==''") {{ item.all }}
                  span(v-else-if="showType=='student'") {{ item.student }}
                  span(v-else) {{ item.faculty }}
            tbody.text-center(v-else)
              tr(v-for="item, i in  orderBy(instiDistribution, sortType[0], sortType[1])", :key="i")
                td.text-center {{ i+1 }}
                td.text-start {{ item.institution }}
                td.text-center.font-weight-bold
                  span(v-if="showType==''") {{ item.all }}
                  span(v-else-if="showType=='student'") {{ item.student }}
                  span(v-else) {{ item.faculty }}
      v-card.overflow-hidden(min-height="634").mt-5
        v-card-text.pb-0
          h5.text-center.mb-n1 <v-icon medium class="mb-1" color="primary"> mdi-database-search </v-icon> <span class="font-weight-bold">Query Admin Logins </span> 
        v-divider
        v-row.mt-n2.px-4.text-center
          v-col(md="6")
            div.caption Starting Date
            v-text-field(
              v-model="start_date",
              type="date",
              filled,
              rounded,
              dense,
              min="2020-12-01",
              :max="maxDate"
            )
          v-col(md="6")
            div.caption Ending Date
            v-text-field(
              v-model="end_date",
              type="date",
              filled,
              rounded,
              dense,
              :min="start_date",
              :max="maxDate"
            )
          v-col.text-end.mt-n7(md="12")
            v-btn(
              color="success",
              @click="getLogins()",
              small
              :disabled = "start_date == '' || end_date == ''"
            ) Show
        v-divider
        v-sheet.overflow-y-auto.overflow-x-hidden(
          height="400",
          width="auto"
        ).px-5
          v-container(
            style="height: auto; width: auto",
            v-if="userResults.length != 0"
          )
            div(v-for="user in userResults", :key="user.id")
              .form-inline(v-for="item in user.count")
                h5 Total Admin Logins
                h5.ml-auto.mr-5.mt-n2 {{ item }}
            .form-inline.text-muted.mt-n3.py-3
              .ml-1 Admin Email
              .ml-auto.mr-1 Logs
            div(v-for="user in userResults", :key="user.id").subtitle-2.font-weight-regular
              div(v-for="item in user.countEmail")
                .d-flex.justify-space-between.bg(
                  v-for="email in orderBy(item, 'value', -1)"
                )
                  p.d-flex.justify-space-between.pr-3.pt-3.pl-2(
                    v-for="e in email"
                  ) {{ e }}
          v-container(style="height: auto; width: auto", v-else)
            h4 To select date:
            h5.font-weight-light CHROME: click the calendar icon <v-icon> mdi-calendar-blank </v-icon>
            h5.font-weight-light FIREFOX: click the input box
            h5.font-weight-light SAFARI: click the input box


</template>

<script>
import firebase from "firebase/app";
import "firebase/functions";
import { db } from "../main";
let getLogins = firebase.functions().httpsCallable("getLogins");
let getInstitution = firebase.functions().httpsCallable("getInstitution");
import colleges from "@/colleges.js";

import Vue2Filters from "vue2-filters";

export default {
  mixins: [Vue2Filters.mixin],
  data() {
    return {
      openedInsti: false,
      loadingInsti: false,
      colleges: colleges.colleges,
      sortSelect: ["Number: Descending", "Number: Ascending", "Alpabetical"],
      typeSelect: [
        { text: "All", value: "", opened: false },
        { text: "Student only", value: "student", opened: false },
        { text: "Faculty only", value: "faculty", opened: false },
      ],
      instiType: "Colleges",
      sortTypeInitial: "Number: Descending",
      sortType: [],
      showType: "",
      colDistribution: [],
      instiDistribution: [],
      institutions: [
        "Institute of Biology",
        "Institute of Chemistry",
        "Institute of Environmental Science and Meteorology",
        "Institute of Mathematics",
        "Marine Science Institute",
        "Materials Science and Engineering Program",
        "National Institute of Geological Sciences",
        "National Institute of Molecular Biology and Biotechnology",
        "National Institute of Physics",
      ],
      userResults: [],
      book_title: "",
      userDialog: false,
      bookDialog: false,
      value: "",
      admin: [],
      start_date: "",
      end_date: "",
      topBooks: [],
      name: "",
      date1: new Date("12/1/2020"),
      date2: new Date(
        new Date().getMonth() +
          1 +
          "/" +
          new Date().getDate() +
          "/" +
          new Date().getFullYear()
      ),
      maxDate:
        new Date().getFullYear() +
        "-" +
        ("0" + (new Date().getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + new Date().getDate()).slice(-2),
      year: new Date().getFullYear().toString(),
      month: (new Date().getMonth() + 1).toString(),
      totals: [],
      totalUsers: 0,
      totalBooks: 0,
      students: 0,
      staff: 0,
      faculty: 0,
      views: [],
      logins: [],
      viewCount: 0,
      monthNumbers: [
        { name: "January", num: 1 },
        { name: "February", num: 2 },
        { name: "March", num: 3 },
        { name: "April", num: 4 },
        { name: "May", num: 5 },
        { name: "June", num: 6 },
        { name: "July", num: 7 },
        { name: "August", num: 8 },
        { name: "September", num: 9 },
        { name: "October", num: 10 },
        { name: "November", num: 11 },
        { name: "December", num: 12 },
      ],
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      selectedYear: new Date().getFullYear(),
      selectedYear1: new Date().getFullYear(),
      years: this.range(2021, new Date().getFullYear()),
      customLogins: "",
      customViews: "",
      dataReady: false,
      dataReadyLogin: false,
      error: false,
      moreViews: false,
      moreLogins: false,
    };
  },

  async created() {
    this.sortType = [this.attr, -1];
    for (let college of this.colleges) {
      this.colDistribution.push({
        institution: college,
        all: 0,
        student: 0,
        faculty: 0,
      });
    }
    this.colDistribution.push({
      institution: "College of Science",
      all: 0,
      student: 0,
      faculty: 0,
    });
    for (let insti of this.institutions) {
      this.instiDistribution.push({
        institution: insti,
        all: 0,
        student: 0,
        faculty: 0,
      });
    }
    this.getBooks();
    this.viewYear();
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(() => {
        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then(async (tokenResult) => {
            this.name = tokenResult.claims.name;
          });
      });
    await this.$bind(
      "totals",
      db.collection("totals").orderBy("__name__", "asc")
    ).then(() => {
      this.totalBooks = this.totals.find(({ id }) => id === "books").count;
      this.students = this.totals.find(({ id }) => id === "student").count;
      let staff = this.totals.find(({ id }) => id === "staff");
      if (staff) this.staff = staff.count;
      this.faculty = this.totals.find(({ id }) => id === "faculty").count;
      this.totalUsers = this.students + this.faculty + this.staff;
    });
    console.log(this.totals);

    this.loginYear();
    this.viewYear();

    await this.$bind("views", db.collection("views"));

    await this.$bind("logins", db.collection("logins"));

    await this.$bind(
      "admin",
      db
        .collection("loginCollection")
        .where("type", "==", "admin")
        .orderBy("date", "desc")
        .limit(15)
    );
  },
  computed: {
    attr: {
      get() {
        if (this.showType == "student") return "student";
        else if (this.showType == "faculty") return "faculty";
        return "all";
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    changeSort() {
      switch (this.sortTypeInitial) {
        case "Number: Descending":
          this.sortType = [this.attr, -1];
          break;
        case "Number: Ascending":
          this.sortType = [this.attr, 1];
          break;
        case "Alpabetical":
          this.sortType = ["institution", 1];
          break;
      }
    },
    changeType() {
      if (
        this.typeSelect.find((x) => x.value == this.showType).opened == true
      ) {
        this.changeSort();
        return;
      }
      this.getInstiData();
    },
    async getInstiData() {
      this.loadingInsti = true;
      if (this.showType == "") {
        this.attr = "all";
      } else {
        this.attr = this.showType;
      }
      const insti = await getInstitution({ type: this.showType });
      const instiData = insti.data.countAll[0];
      this.CSTotal = 0;
      for (const [key, value] of Object.entries(instiData)) {
        if (this.institutions.includes(`${key}`)) {
          const obj =
            this.instiDistribution.find((x) => x.institution == `${key}`) || {};
          obj[this.attr] = `${value}`;
          this.CSTotal += Number(`${value}`);
        } else {
          const obj =
            this.colDistribution.find((x) => x.institution == `${key}`) || {};
          obj[this.attr] = `${value}`;
        }
      }
      this.colDistribution.find((x) => x.institution == "College of Science")[
        this.attr
      ] = this.CSTotal;
      this.loadingInsti = false;
      this.openedInsti = true;
      this.typeSelect.find((x) => x.value == this.showType).opened = true;
      this.changeSort();
    },
    range(start, end) {
      return Array(end - start + 1)
        .fill()
        .map((_, idx) => start + idx);
    },
    async getLogins() {
      let userNumber = await getLogins({
        startDate: this.start_date,
        endDate: this.end_date,
        type: "admin",
      });
      this.userResults = userNumber;
    },

    async getBooks() {
      this.$bind(
        "topBooks",
        db.collection("books").orderBy("views", "desc").limit(10)
      );
    },
    async loginYear() {
      this.dataReadyLogin = false;
      await this.$bind(
        "customLogins",
        db
          .collection("logins")
          .where("__name__", "==", this.selectedYear.toString())
      ).then(() => {
        this.dataReadyLogin = true;
      });
    },
    async viewYear() {
      this.dataReady = false;
      await this.$bind(
        "customViews",
        db
          .collection("views")
          .where("__name__", "==", this.selectedYear1.toString())
      ).then(() => {
        this.dataReady = true;
      });
    },
    sum(obj) {
      if (obj) {
        return Object.keys(obj).reduce(
          (sum, key) => sum + parseFloat(obj[key] || 0),
          0
        );
      }
    },
    increase(obj) {
      if (obj) {
        return (
          (
            (
              (obj[new Date().getMonth() + 1] - obj[new Date().getMonth()]) /
              obj[new Date().getMonth()]
            ).toFixed(4) * 100
          )
            .toString()
            .slice(0, 5) + "%"
        );
      }
    },
    percentage(obj) {
      if (obj) {
        return Math.round((obj / this.totalUsers) * 1000) / 10;
      }
      return 0;
    },
    getValues(obj) {
      if (obj) {
        return Object.values(obj);
      }
    },
  },
};
</script>
<style>
.covered {
  background: rgba(2, 0, 115, 0.8);
}

.bottom-gradient {
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.4) 2%,
    transparent 70px
  );
}
.bg:hover {
  background: #ececec;
}
.v-sheet--offset {
  top: -24px;
  position: relative;
}
</style>
