export default {
  colleges: [
    "Archaeological Studies Program",
    "Asian Center",
    "Asian Institute of Tourism",
    "Cesar E.A. Virata School of Business",
    "College of Architecture",
    "College of Arts and Letters",
    "College of Education",
    "College of Engineering",
    "College of Fine Arts",
    "College of Home Economics",
    "College of Human Kinetics",
    "College of Law",
    "College of Mass Communication",
    "College of Music",
    "College of Science",
    "College of Social Sciences and Philosophy",
    "College of Social Work and Community Development",
    "Institute of Islamic Studies",
    "National College of Public Administration and Governance",
    "School of Economics",
    "School of Labor and Industrial Relations",
    "School of Library and Information Studies",
    "School of Statistics",
    "School of Urban and Regional Planning",
    "Technology Management Center",
    "UPD Extension Program in Pampanga and Olongapo",
  ],
  courses: [
    "Bio",
    "Chem",
    "Math",
    "MS",
    "TMEM",
    "Env. Sci.",
    "Meteo",
    "MSE",
    "GE",
    "Geol",
    "MBB",
    "Physics",
    "App Physics",
  ],
};
